  /* .App {
  text-align: center;
} */
#chart {
  max-width: 650px;
  margin: 35px auto;
}


/* .Nav {
  border-bottom-color: transparent;
} */


/* .nav-pills > .active > a,
.nav-pills > .active > a:hover,
.nav-pills > li > a:hover {
text-decoration: none;
font-weight: bold;
border-radius:0px;
border-bottom-width: 4px;
border-bottom-style: solid;
border-bottom-color: #FF6600;
    color: #000 !important;
    background-color: transparent !important;
}
.nav-pills > li > a{
    border-radius:0px;
    color:#000;
} */

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


p {
  margin-bottom: 0px;
}

small.abc {
   padding-left: 0px
}

#Login{
  max-width: 440px;
  margin: 50px auto;

}.card-header{
  border-bottom:none;
}


.Login .card-header {   
  font-size: 18px;
  font-weight: 300;
  padding-left: 0;
  padding-right: 0;
  padding-top: 1.5384rem;
  margin: 0 0.538rem;
  border-bottom-width: 0;
  border-radius: 3px 3px 0 0;
  background-color: transparent;
  text-align: center;
}.Login .card-header {
  padding: 0.7692rem 1.538rem;
  margin-bottom: 0;
  border-bottom: 1px solid rgba(27, 15, 15, 0.125);
}
.Login .container-fluid .card .card-header {
  text-align: center;
  margin-bottom: 30px;
  padding-top: 45px;
  padding-bottom: 0;
}
.Login .card .card-body {
  padding: 20px 30px 15px;
}
.Login .login-submit{
  padding: 19px 0 0;
  margin-bottom: 1.3842rem;
}
.Login .form-control {
  border-width: 1px;
  border-top-color: #bdc0c7;
  box-shadow: none;
  padding: 10px 12px;
  font-size: 0.9rem;
  transition: none;
  height: 3.692rem;
}
.Login label {
  font-size: 14px;
}
#btnLogin{
  width: 100%;
  height: 50px;
  font-size: 18px;
}
#nav-tab-scroll{
  overflow: auto;
  white-space: nowrap;
}
nav{
  overflow-x: auto;
}
div.scrollmenu {
  background-color: transparent;
  overflow-x: auto;
  white-space: nowrap;
  color: #61dafb;
}

div.scrollmenu a {
  display: inline-block;
  text-align: center;
  padding: 14px;
  
  /* text-decoration: none; */
}

div.scrollmenu :hover{
  /* color: red; */
}
div.scrollmenu a:hover {
  background-color: #0062cc;
  border-bottom: 4px;
  /* color: #0062cc; */
}

/* #chart01{
  height: 1200px;
  width: 1000px;
} */
/* LineVertical */
.LineVertical {
  border-left: 1px solid #dee2e6;
  height: 110px;
  position: absolute;
  left: 56px;
  margin-left: -3px;
  top: 0px;
}
#logout{
  background-color: #e8eaec!important;
}

/* LineVertical */
a.nav-link.active {
  padding-bottom: 13px;
  /* color: rgb(153, 252, 61); */
  font-weight: bolder;
  border-bottom-color: #0062cc ;
  border-bottom: 3px solid !important;
}
.highcharts-scrolling .highcharts-inner-container {
  /* padding-left: 50px; */
  /* height: 400px; min-width: 320px; max-width: 600px; margin: 0 auto */
}


.loaderModal {
  /* display: none;  */
  /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 15%; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}
​
.loaderModalContent {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
}